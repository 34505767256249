import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TiempoProductivo = () => {
  // Datos organizados por semanas
  const data = {
    labels: ['28/10', '4/11', '2/12', '9/12'], // Semanas del mes
    datasets: [
      {
        label: 'Horas Productivas',
        data: [10, 20, 20, 50], // Total de horas productivas por semana
        borderColor: '#f87887',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        pointBackgroundColor: '#f87887',
        pointBorderColor: '#f87887',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true }, // Muestra la leyenda
      title: { display: true, text: 'Horas Productivas por Semana' },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Horas',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Semanas',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default TiempoProductivo;
