import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Registrar los elementos de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TareasSemanal = () => {
  // Datos estáticos para 4 semanas basados en los datos del prompt anterior
  const datos = [
    { _id: { semana: 1, estado: 'Completado' }, total: 10 },
    { _id: { semana: 1, estado: 'Pendiente' }, total: 5 },
    { _id: { semana: 2, estado: 'Completado' }, total: 15 },
    { _id: { semana: 2, estado: 'Pendiente' }, total: 3 },
    { _id: { semana: 3, estado: 'Completado' }, total: 20 },
    { _id: { semana: 3, estado: 'Pendiente' }, total: 10 },
    { _id: { semana: 4, estado: 'Completado' }, total: 18 },
    { _id: { semana: 4, estado: 'Pendiente' }, total: 6 },
  ];

  // Procesar los datos estáticos para crear las listas de entregadas y no entregadas
  const semanas = [1, 2, 3, 4];
  const entregadas = semanas.map(semana => 
    datos
      .filter(item => item._id.semana === semana && item._id.estado === 'Completado')
      .reduce((total, item) => total + item.total, 0)
  );

  const noEntregadas = semanas.map(semana => 
    datos
      .filter(item => item._id.semana === semana && item._id.estado === 'Pendiente')
      .reduce((total, item) => total + item.total, 0)
  );

  const semanalData = { semanas: semanas.map(semana => `Semana ${semana}`), entregadas, noEntregadas };

  // Datos para la gráfica
  const data = {
    labels: semanalData.semanas,
    datasets: [
      {
        label: 'Entregadas',
        data: semanalData.entregadas,
        backgroundColor: '#b6d89c', // Color para tareas entregadas
      },
      {
        label: 'No Entregadas',
        data: semanalData.noEntregadas,
        backgroundColor: '#f87887', // Color para tareas no entregadas
      },
    ],
  };

  // Opciones de configuración para la gráfica
  const options = {
    responsive: true,
    plugins: {
      legend: { display: true }, // Mostrar la leyenda de la gráfica
      title: { display: true, text: 'Tareas Entregadas vs No Entregadas por Semana' },
    },
  };

  // Renderizar la gráfica
  return <Bar data={data} options={options} />;
};

export default TareasSemanal;
