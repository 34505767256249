import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import {WEBSERVICE_IP} from '../functions/roots/webserviceIP';

const Verificacionc = () => {
  const { token } = useParams();  // Token recibido en el enlace
  const navigate = useNavigate();
  const [mensaje, setMensaje] = useState('');
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    // Verificar si el token es válido
    axios.get(`${WEBSERVICE_IP}/usuarios/verificar/${token}`)
      .then(response => {
        setUsuario(response.data);
      })
      .catch(error => {
        setMensaje('Token inválido o ya verificado.');
      });
  }, [token]);

  const handleConfirmarRegistro = async () => {
    try {
      const response = await axios.post(`${WEBSERVICE_IP}/usuarios/confirmar-registro`, { 
        token, 
        confirmar: true  
      });

      if (response.status === 200) {
        setMensaje('¡Correo verificado y registro completado exitosamente!');
        setTimeout(() => {
          navigate('/inicio-sesion');
        }, 2000);
      }
    } catch (error) {
      setMensaje('Error al completar el registro. Intenta más tarde.');
    }
  };

  const handleCancelarRegistro = async () => {
    try {
      const response = await axios.post(`${WEBSERVICE_IP}/usuarios/confirmar-registro`, { 
        token, 
        confirmar: false  // Indicar que el usuario ha cancelado
      });
  
      if (response.status === 200) {
        setMensaje('Registro cancelado. El usuario ha sido eliminado.');
        setTimeout(() => {
          navigate('/registro');  // Redirigir al registro si decide cancelar
        }, 2000);
      }
    } catch (error) {
      setMensaje('Error al cancelar el registro. Intenta más tarde.');
    }
  };

  return (
    <div className="verificacion-container">
      <div className="verificacion-form">
        <h2>Verificación de Correo</h2>
        {usuario ? (
          <div>
            <p>¿Es este tu correo? {usuario.correo}</p>
            <button onClick={handleConfirmarRegistro}>Sí</button><br></br>
            <button onClick={handleCancelarRegistro}>No</button>
          </div>
        ) : (
          <p className="verificacion-mensaje">{mensaje}</p>
        )}
      </div>
    </div>
  );
};

export default Verificacionc;
